import React from "react"
import { graphql } from "gatsby"

import Content, { HTMLContent } from "../components/Content"
import Layout, { Section } from "../components/Layout"
import PageImage from "../components/PageUnsplashImage"
import { ContactForm } from "../components/ContactForm"
import { Member } from "../components/Members"

export const GenericPageTemplate = ({
  title,
  content,
  image,
  imageCredit,
  contentComponent,
  contentClasses,
  location,
  youtube,
  callToAction,
  members,
}) => {
  const PageContent = contentComponent || Content
  const formVariation = location.pathname.replace("/", "")

  return (
    <div>
      <PageImage src={image} credit={imageCredit}>
        <h1 className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen">
          {title}
        </h1>
      </PageImage>

      <Section className="container is-max-desktop">
        <PageContent className={["content markdown", contentClasses].join(" ")} content={content} />

        {youtube.href && (
          <div className="youtube-iframe-container">
            <div>
              <iframe
                className="youtube-iframe"
                src={youtube.href}
                title={youtube.title}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
          </div>
        )}

        {members &&
          members
            .sort((m1, m2) => m1.name.localeCompare(m2.name))
            .map((m) => <Member member={m} />)}

        <div className="mt-3">
          {callToAction && (
            <>
              <br />
              <div>{callToAction}</div>
              <br />
            </>
          )}
          <ContactForm variation={formVariation} />
        </div>
      </Section>
    </div>
  )
}

const GenericPage = ({ data, location }) => {
  const post = data.markdownRemark

  return (
    <Layout>
      <GenericPageTemplate
        location={location}
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        image={post.frontmatter.image}
        imageCredit={post.frontmatter.imageCredit}
        youtube={post.frontmatter.youtube || {}}
        callToAction={post.frontmatter.callToAction}
        contentClasses={post.frontmatter.contentClasses || ""}
        members={post.frontmatter.members}
      />
    </Layout>
  )
}

export default GenericPage

export const query = graphql`
  query GenericPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        contentClasses
        callToAction
        youtube {
          title
          href
        }
        imageCredit {
          author
          href
        }
        image
        members {
          name
          description
          mpriority
          role
          linkedin
          instagram
          lattes
          twitter
          picture
        }
      }
    }
  }
`
